// maybe move this to a cookie file under notifications

initCookie()


function initCookie()
{
    const name = 'cookieConsent'
    const container = Util.qs('#'+name)
    if(!container) return

    let delay = 1
    let value = 1
    let options = {
        expires: 365,
        path: '/',
        domain: window.location.hostname,
    }
    let btn = Util.qs('.accept')
    let tl = gsap.timeline({paused: true})

    tl.set(container, { display: 'none', opacity: 0, yPercent: 100})
    tl.to(container, { display: 'block'})
    tl.to(container, { opacity: 1, yPercent: 0})

    window.addEventListener('load', event => {
        if(Cookies.get(name) == undefined) {
            setTimeout(e => tl.play() , delay*1000)
        }
    })

    btn.addEventListener('click', e => {
        e.preventDefault();
        Cookies.set(name, value, options);
        // show cookie popup
        tl.reverse();
    })
}