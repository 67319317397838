export default class Util {
    constructor() 
    {
        window.Util = this

        this.emailMasking()
    }

    /** 
    * Dom
    */

    qs(selector, parentNode = document)
    {
        if(typeof parentNode !== 'object') return false
        const node = parentNode.querySelector(selector)
        if(node === null) return false
        return node 
    }

    qsa(selector, parentNode = document) 
    {
        if(typeof parentNode !== 'object') return false
        return [...parentNode.querySelectorAll(selector)]
    }

    createElement(type, options = {}) {
        const ele = document.createElement(type)

        for (let prop in options) {
            switch(true) {
                // is class
                case (prop === 'class'):
                    if(Array.isArray(options[prop])) {
                        options[prop].forEach(name => ele.classList.add(name) )
                        break
                    } 
                    ele.classList.add(options[prop])
                    break
                // is dataset
                case (prop === 'dataset'):
                    if(typeof options[prop] === 'object' && options[prop] !== null) {
                        for(let key in options[prop]) {
                            ele.dataset[key] = options[prop][key]
                        }
                    }
                    break
                case (prop === 'innerText'):
                    ele.innerText = options[prop]
                    break
                // is attribute 
                default:
                    ele.setAttribute(prop, options[prop])
                break
            }
        }
        return ele
    }

    emailMasking() {
        const emails = this.qsa('[href^="mailto:"]')

        emails.forEach(email => {
            email.href = email.href.replace('[at]', '@');
            email.innerText = email.innerText.replace('[at]', '@');
        })
    }
}
