initNav()

function initNav() {
    let input = Util.qs('#nav')
    let nav = Util.qs('.header__nav')
    let navBG = Util.qs('.header__nav-bg')

    gsap.set(nav, { display: 'block', autoAlpha: 0})

    input.onchange = e => anim(input.checked)

    function anim(boolean) {
        let tl = gsap.timeline({paused: true})

        tl
            .addLabel('Start')
            .set('html', {overflowY: 'hidden'})
            .to(nav, {autoAlpha: 1})

            .addLabel('Middle')
            .to(nav, {autoAlpha: 0})
            .set('html', {overflowY: 'auto'})

            .addLabel('End')

        if(boolean) {
            tl.seek('Start').tweenTo('Middle')
        } else {
            tl.seek('Middle').tweenTo('End')
        }
    }
}