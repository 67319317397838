init()

function init()
{
    const dom = document
    const carousels = Util.qsa('.swiper', dom)

    carousels.forEach(ele => {
        // check for slider variations
        let options = {
            loop: true,
            init: false,
            navigation: {    
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        }

        if(ele.classList.contains('swiper--creative')) {
            options.grabCursor = true
            options.speed = 500
            options.autoplay = { delay: 6000 }
            options.pagination = {
                el: '.swiper-pagination',
                clickable: true,
            }
            options.effect = 'creative'
            options.creativeEffect = {
                prev: {
                    // shadow: true,
                    translate: [0, 0, -400],
                },
                next: {
                    translate: ['100%', 0, 0],
                },
            }
        }
        if(ele.classList.contains('swiper--3slides')) {
            options.breakpoints = {
                1270: { 
                    slidesPerView: 3,
                },
                767: { 
                    slidesPerView: 2,
                }
            } 
        }

        const swiper = new Swiper('.swiper', options)

        swiper.init()

    })
}