initScroll()
updateCurrentPage()

barba.hooks.beforeEnter((data) => {
    updateCurrentPage()
})

function initScroll() 
{
    let direction = 1
    let progress = 0
    let state = 'default'
    
    ScrollTrigger.create({
        // markers: true,
        id: 'header',
        trigger: document.body,
        start: '70px top',
        end: 'bottom bottom',
        onUpdate: self => scrollHandler(self)
    })

    function scrollHandler(self) 
    {
        direction = self.direction
        progress = self.progress

        if(progress == 0) {
            state = 'blend'
        } else if(direction == -1) {
            state = 'default'
        } else {
            state = 'hide'
        }

        document.body.dataset.headerScroll = state
    }

}

function updateCurrentPage() 
{
    const listEle = Util.qsa('.header__nav li:not(.btn--fill)')

    // clear current class
    listEle.forEach(ele => ele.classList.remove('current-menu-item'))
    listEle.forEach(ele => {
        const link = Util.qs('a', ele)
        if (window.location.pathname == link.getAttribute('href')) {
            ele.classList.add('current-menu-item')
        }
    })

}
